'use strict';

let favouriteExperiencesCookie;

function initFavouriteExperiencesInSection(section) {
    favouriteExperiencesCookie = getCookie(userFavouriteExperiencesCookieKey);
    $(section).find('.js-single-experience').each(function () {
        initFavouriteExperience($(this));
    });
    if ($(section).hasClass('js-experience-page')) {
        initFavouriteExperience(section);
    }
}

function setExperienceFavourite(experienceItem) {
    $(experienceItem).addClass('favorite-experience-checked');
    $(experienceItem).parent().attr('title', 'Remove from favorite experiences');
}

function setExperienceUnfavourite(experienceItem) {
    experienceItem.removeClass('favorite-experience-checked');
    experienceItem.parent().attr('title', 'Add to favorite experiences');

    if (experienceItem.parents('.experience-slider') && parseInt(experienceItem.parents('.experience-slider').attr('data-favoritescarousel'))) {
        experienceItem.parents('.slider-item').remove();
        if (!$('.js-single-experience').length) {
           $('.favourites-experiences-box').remove();
        }
    }

    if (!$('.favourites-experiences-box').length && !$('.favorite-articles-box').length) {
        $('.favorites-container').find('.js-info-favourites-page').removeClass('no-display');
    }
}

function initFavouriteExperience(experienceItem) {
    const favouriteExperiences = favouriteExperiencesCookie ? JSON.parse(decodeURIComponent(favouriteExperiencesCookie)) : [];
    const addToFavoritesExperiencesIcon = $(experienceItem).find('.js-add-favorite-experience');
    const experienceSlug = addToFavoritesExperiencesIcon.attr('data-slug');
    addToFavoritesExperiencesIcon.off('click', processFavouriteExperience).on('click', processFavouriteExperience);

    if (Object.values(favouriteExperiences).includes(experienceSlug) || userFavouriteExperiencesSlugs.includes(experienceSlug)) {
        setExperienceFavourite(addToFavoritesExperiencesIcon);
    } else {
        setExperienceUnfavourite(addToFavoritesExperiencesIcon);
    }
}
const processFavouriteExperience = (e) => {
    const target = $(e.target);
    target.attr('disabled', true);
    if (target.hasClass('favorite-experience-checked')) {
        setExperienceUnfavourite(target);
    } else {
        setExperienceFavourite(target);
        if (typeof trackFavouriteExperienceToKlaviyo === 'function') {
            trackFavouriteExperienceToKlaviyo(target.attr('data-slug'));
        }
    }

    $.ajax({
        url: '/api/user-favourite-experiences/' + target.attr('data-slug'),
        type: 'POST',
        success: function (response) {
            target.attr('disabled', false);
        },
        error: (response) => {
            console.log(response);
        }
    });
};