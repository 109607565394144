'use strict';

const LoginViewMessages = function() {
    this.selector = '.js-login-view-messages';
    this.init = function () {
        const passwordBlock = $('.js-password-block');
        const emailBlock = $('.js-email-block');

        $('input').not('[type="submit"]').on('keypress', function(event) {
            if (event.key === "Enter") {
                event.preventDefault();
                if ($(this).attr('id') === "password") {
                    $('#password_confirmation').focus();
                }
            }
        });

        $(this.selector).find('.js-next-button').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            clearAllError();
            validateLoginField(document.getElementById('email'), getEmailValidationErrorMessage);

            if (showJSValidationError()) {
                return false;
            }

            toggleFormField(passwordBlock, emailBlock);
        });

        $(this.selector).find('.js-back-btn').on('click', function () {
            toggleFormField(passwordBlock, emailBlock);
            clearAllError();
        });

        $(this.selector).find('.js-show-symbols').on('click', function () {
            const passwordInput = $(this).prev('input');

            if (passwordInput.attr('type') === 'password') {
                passwordInput.attr('type', 'text');
                $(this).addClass('active');
            } else {
                passwordInput.attr('type', 'password');
                $(this).removeClass('active');
            }
        });
        setLogInButtonEvent($(this.selector).find('#submit'));
    };
    const setLogInButtonEvent = function (btn, e) {
        btn.on('click', function (e) {
            e.preventDefault();

            clearAllError();

            validateLoginField(document.getElementById('email'), getEmailValidationErrorMessage);
            validateLoginField(document.getElementById('password'), validatePassword, {'min' : 8, 'max' : 16});
            validateLoginField(document.getElementById('password_confirmation'), validateLoginIdentical, {'linkField' : '#registration_password'});

            if (showJSValidationError()) {
                return false;
            }
        });
    }
    const toggleFormField = function (passwordBlock, emailBlock) {
        passwordBlock.toggleClass('hidden');
        emailBlock.toggleClass('hidden');
    }
};

new SelectorInitiator().setObject(new LoginViewMessages());