'use strict';

let favouriteArticlesCookie;

function initFavouriteArticlesInSection(section) {
    favouriteArticlesCookie = getCookie(userFavouriteArticlesCookieKey);

    $(section).find('.js-single-article').each(function () {
        initFavouriteArticle($(this));
    });
}

function setArticleFavourite(articleItem) {
    if (articleItem.parents('.travel-magazine-main-articles') && articleItem.parents('.card-block-item').find('.card-block-img').find('img').attr('src')) {
        $(articleItem).addClass('favorite-article-checked');
        $(articleItem).parent().attr('title', 'Remove from favorite articles');
    } else {
        $(articleItem).addClass('favorite-article-checked');
        $(articleItem).parent().attr('title', 'Remove from favorite articles');
    }
}

function setArticleUnfavourite(articleItem) {
    articleItem.removeClass('favorite-article-checked');
    articleItem.parent().attr('title', 'Add to favorite articles');

    if (articleItem.parents('.carousel-content-page') && parseInt(articleItem.parents('.carousel-content-page').attr('data-favoritescarousel'))) {
        articleItem.parents('.js-single-article').remove();
        if (!$('.js-single-article').length) {
            $('.favorite-articles-box').remove();
        }
    }

    if (!$('.favourites-experiences-box').length && !$('.favorite-articles-box').length) {
        $('.favorites-container').find('.js-info-favourites-page').removeClass('no-display');
    }
}

function initFavouriteArticle(articleItem) {
    const favouriteArticles = favouriteArticlesCookie ? JSON.parse(decodeURIComponent(favouriteArticlesCookie)) : [];
    const isSliderItem = $(articleItem).hasClass('slider-item');

    let addToFavoritesArticlesIcon;
    addToFavoritesArticlesIcon =  isSliderItem ? $(articleItem).find('.favorite-article-icon-box').find('.js-add-favorite-article') : $(articleItem).find('.js-add-favourite-article');
    addToFavoritesArticlesIcon.off('click', processFavouriteArticle).on('click', processFavouriteArticle);

    const articleSlug = addToFavoritesArticlesIcon.attr('data-slug');

    if (Object.values(favouriteArticles).includes(articleSlug) || userFavouriteArticlesSlugs.includes(articleSlug)) {
        setArticleFavourite(addToFavoritesArticlesIcon);
    } else {
        setArticleUnfavourite(addToFavoritesArticlesIcon);
    }
}

const processFavouriteArticle = function (e) {
    const target = $(e.target);
    target.attr('disabled', true);
    if (target.hasClass('favorite-article-checked')) {
        setArticleUnfavourite(target);
    } else {
        setArticleFavourite(target);
    }

    $.ajax({
        url: '/api/user-favourite-articles/' + $(e.target).attr('data-slug'),
        type: 'POST',
        success: function (response) {
            target.attr('disabled', false);
        },
        error: function (response) {
            console.log(response);
        }
    });
};