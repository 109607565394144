'use strict';

const ServiceWorkerService = function() {
    this.serviceWorkerConfig = {
        onSuccess: function () {
            if ('Notification' in window && 'PushManager' in window) {
                (new PnService()).sbscrbVisitor();

            }
        },
    };

    let self = this;
    const swUrl = `/service-worker-frontend.js`;

    this.init = function() {
            navigator.serviceWorker.register(swUrl).then(registration => {
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;

                    if (installingWorker === null) {
                        return;
                    }
                };

                if (self.serviceWorkerConfig && self.serviceWorkerConfig.onSuccess) {
                    self.serviceWorkerConfig.onSuccess();
                }
            }).catch(error => {
                console.error('Error during service worker registration:', error);
            });
    };
};
