function generalTabs(headerSelector, tabSelector, contentSelector, activeClass, isFirstTabActive = false) {
    const header = document.querySelector(headerSelector),
        tab = document.querySelectorAll(tabSelector),
        content = document.querySelectorAll(contentSelector);

    function hideTabContent() {
        content.forEach((item) => {
            item.classList.add('no-display');
        });

        tab.forEach((item) => {
            item.classList.remove(activeClass);
        });
    }

    function showTabConent(i = 0) {
        content[i].classList.remove('no-display');
        tab[i].classList.add(activeClass);
    }

    hideTabContent();
    isFirstTabActive && showTabConent();

    header.addEventListener('click', (e) => {
        e.preventDefault();
        const target = e.target;
        if (
            target &&
            (target.classList.contains(tabSelector.replace(/\./, '')) ||
                target.parentNode.classList.contains(tabSelector.replace(/\./, '')))
        ) {
            tab.forEach((item, idx) => {
                if (target === item || target.parentNode === item) {
                    hideTabContent();
                    showTabConent(idx);
                }
            });
        }
    });
}
