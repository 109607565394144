var ObserverAdapter = function() {
    /**
     * @type {Array}
     */
    var observables = [];

    /**
     * @param callback
     */
    this.subscribe = function(callback) {
        typeof callback === 'function' ? observables.push(callback) : null;
    };

    /**
     * @param callback
     */
    this.unsubscribe = function(callback) {
        if (typeof callback === 'function') {
            observables = observables.filter(function(setCallback) {
                return setCallback.toString() !== callback.toString();
            });
        }
    };

    /**
     * @param args
     */
    this.run = function(args) {
        observables.map(function(callback) {
            callback(args);
        });
    };
};
