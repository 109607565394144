'use strict';

const SetEventForBackToTop = function() {
    this.selector = '.back-to-top-btn';
    let timeoutId;
    this.init = function() {

        checkPositionScroll();
        document.querySelector(this.selector).addEventListener('click', backToTop);
        window.addEventListener('scroll', checkPositionScroll);
    };
    const backToTop = function() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const checkPositionScroll = function() {
        clearTimeout(timeoutId);
        window.pageYOffset > 1000
            ? showButton(backToTopBtn)
            : backToTopBtn.classList.remove('show');
    };

    function showButton(btn) {
        btn.classList.add('show');
        if (isMobile) {
            timeoutId = setTimeout(function () {
                btn.classList.remove('show');
            }, 3000);
        }
    }
};

new SelectorInitiator().setObject(new SetEventForBackToTop());
