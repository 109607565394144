'use strict';

const MultiCurrency = function() {
    this.selector = '.js-currency';

    let self = this;
    let cookieKey;

    this.init = function() {
        cookieKey = $('.currency-content').data('cookiekey');
        setCurrencyFromCookie();

        $('.js-currency-btn, .js-currency-arrow-down, .js-currency-btn-span').on('click', function () {
            if ($(self.selector).hasClass('currency--opened')) {
                closeCurrencyMenuOutsideClick();
                return false;
            } else {
                openCurrencyMenu($(this).parent().parent().find(self.selector));
            }
        });

        document.addEventListener('click', event =>
            shouldSectionClose(event) ? closeCurrencyMenuOutsideClick() : null
        );

        selectCurrency();
    };

    /**
     * opens or closes the currency menu;
     */
    let openCurrencyMenu = function(obj) {
        obj.addClass('currency--opened');
    };

    /**
     * closes currency menu when clicking outside;
     * e {obj} = event;
     */
    let closeCurrencyMenuOutsideClick = function() {
        $(self.selector).removeClass('currency--opened');
    };

    const shouldSectionClose = function(event) {
        return !$(event.target).is('.js-currency-btn') &&
            !$(event.target).is('.currency__list') &&
            !$(event.target).is('.currency-icon-btn') &&
            !$(event.target).is('.js-currency-arrow-down') &&
            !$(event.target).is('.js-currency-btn-span');
    };

    /**
     * sets the currency value in the user's cookies;
     * the default period is 2 months;
     * obj {this} - js object;
     */
    let setCurrencyToCoolie = function(obj) {
        const MONTH = 2592000; // 2592000 seconds = 30 days;
        let lifeTimeCookie = new Date(new Date().getTime() + 2 * MONTH).toUTCString(); // set cookies on 2 month;
        document.cookie = cookieKey + '=' + obj.next().find('.currency-code').text() + '; expires =' + lifeTimeCookie + ';path=/;SameSite=None; Secure;';
    };

    /**
     * controls when currency will be changed;
     */
    let selectCurrency = function() {
        $('.currency__list')
            .find('input[type=radio]')
            .on('change', function() {
                $('.currency-content')
                    .children('strong')
                    .text(
                        $(this)
                            .next()
                            .find('.currency-code')
                            .text()
                    );
                setCurrencyFromCookie();
                setCurrencyToCoolie($(this));
                openCurrencyMenu($(this).parent().parent());

                location.reload();
            });
    };

    /**
     * controls when currency will be changed;
     *
     * @return {string}
     */
    let getCurrentCookie = function() {
        const cookiesList = document.cookie
            .split('; ')
            .map(x => Object.assign({}, { [x.split('=')[0]]: x.split('=')[1] }));

        let cookieCurrency = 'USD';

        for (let cookiesItem of cookiesList) {
            cookiesItem[cookieKey] ? (cookieCurrency = cookiesItem[cookieKey]) : null;
        }

        return cookieCurrency;
    };

    /**
     * checked and sets currency according cookies value;
     */
    let setCurrencyFromCookie = function() {
        $('.currency__list')
            .find('input[value=' + getCurrentCookie() + ']')
            .prop('checked', 'checked');

        let curr = '';
        let code = '';
        const currentCookie = getCurrentCookie();

        if (currentCookie === 'AUD') {
            curr = 'dollar';
            code = '$';
        } else if (currentCookie === 'EUR') {
            curr = 'euro';
            code = '€';
        } else if (currentCookie === 'GBP') {
            curr = 'pound';
            code = '£';
        } else if (currentCookie === 'USD') {
            curr = 'dollar';
            code = '$';
        } else if (currentCookie === 'CAD') {
            curr = 'dollar';
            code = '$';
        }

        $('.currency-icon-btn').prop('className', 'currency-icon-btn ' + curr); 
        $('.js-currency-btn-span').text(currentCookie);
        $('.js-currency-icon').text(code);
    };
};

new SelectorInitiator().setObject(new MultiCurrency());