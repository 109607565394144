const CookieBanner = function() {
    this.selector = '.wrapper';
    const cookieBlock = `
        <article class="cookie-banner">
            <div class="cookie-banner-body">
                <p class="cookie-banner-text">By using City Unscripted you agree to our <a class="cookie-baner-link" href="${linkPoliciesCookies}" target="_blank" rel="noopener">Cookie Policy</a></p>
                <button class="cookie-banner-btn a-btn yellow" aria-label="Accept cookie policy">OK</button>
            </div>
        </article>`;

    this.init = function() {
        $(window).one('scroll', function() {
            location.href === DOMAIN ? checkUserCookie() : null;
        });
    };

    const checkUserCookie = function() {
        const cookiesList = document.cookie
            .split('; ')
            .map(x => Object.assign({}, { [x.split('=')[0]]: x.split('=')[1] }));
        let isCheckCookieBanner = true;
        for (let cookiesItem of cookiesList) {
            cookiesItem.cityunscriptedCookiePolicy ? (isCheckCookieBanner = false) : null;
        }
        isCheckCookieBanner ? addedCookieBannerAndSetEvent() : null;
    };

    const addedCookieBannerAndSetEvent = function() {
        document.body.insertAdjacentHTML('beforeEnd', cookieBlock);
        document.getElementsByClassName('cookie-banner')[0].addEventListener('click', checkClick);
    };

    const checkClick = function() {
        event.target !== document.getElementsByClassName('cookie-baner-link')[0]
            ? (addedInfoAboutCookie(), removeCookieBaner())
            : null;
    };

    const removeCookieBaner = function() {
        document.getElementsByClassName('cookie-banner')[0].remove();
    };

    const addedInfoAboutCookie = function() {
        const MONTH = 2592000; // 2592000 seconds = 30 days;
        let lifetimeCookie = new Date(new Date().getTime() + MONTH * 1000).toUTCString();
        document.cookie = 'cityunscriptedCookiePolicy=I_agree_with_their_policy; expires =' + lifetimeCookie  + ';SameSite=None; Secure;';
    };
};

new SelectorInitiator().setObject(new CookieBanner());
