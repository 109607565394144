'use strict';

const TAlogo = function() {
    this.selector = '.ta-settings';
    this.init = function() {
        if (document.querySelector('.ta-settings-content') && document.querySelector('.ta-settings-btn')) {
            document.querySelectorAll('.ta-settings-btn').forEach(btn => {
                btn.addEventListener('click', (e) => toggleTAsettings(e, btn));
            });
        }
    };
    const toggleTAsettings = function(e, btn) {
        e.stopPropagation();
        const contentPopup = btn.closest('.ta-settings').querySelector('.ta-settings-content');
        contentPopup.classList.toggle('show');
        if(contentPopup.classList.contains('show')) {
            document.addEventListener('click', clickOutside);
        }
    };

    const closeTaSettings = function() {
        document.querySelectorAll('.ta-settings-content').forEach(item => {
            item.classList.remove('show');
        });
    };

    function clickOutside(event) {
        if(!event.target.closest('.ta-settings-content')) {
            closeTaSettings();
            document.removeEventListener('click', clickOutside);
        }
    }
};

new SelectorInitiator().setObject(new TAlogo());