'use strict';
const MainNav = function() {
    this.selector = '.main-nav';
    const self = $(this.selector);

    this.init = function() {
        self.find('.js-burger-btn').on('click', openBurgerMenu);
        self.find('.js-burger-btn-close').on('click', closeBurgerMenu);

        self.find('.js-main-menu-item-desktop, .js-main-menu-item-mobile').on('mouseover', function () {
            self.find('.js-main-menu-item-desktop').removeClass('active');
            $(this).addClass('active');
            $(this).trigger('click');
            self.find('.js-tab-content').addClass('open');
        });

        self.on('mouseleave', function () {
            self.find('.js-main-menu-item-desktop').removeClass('active');
            $('.main-nav-content').removeClass('open');
        });

        self.find('.js-return').on('click', function() {
            self.find('.js-tab-content').removeClass('open');
            self.find('.js-main-menu-item').removeClass('active');
        });

        if (isMobile) {
            self.find('.secondary-menu-items').addClass('no-display');
            self.find('.js-secondary-menu-title').on('click', function () {
                self.find('.secondary-menu-items').addClass('no-display');
                self.find('.js-secondary-menu-title').removeClass('active');
                $(this).addClass('active');
                $(this).parent().find('.secondary-menu-items').toggleClass('no-display');
            });
        }

        const debounceSearch = debounce(search, 500);
        self.find('.js-search-input').on('input', debounceSearch);

        self.find('.js-search').on('click', function () {
            $(window).scrollTop(0);
            function lockScroll() {
                if($(window).scrollTop() === 0) {
                    scrollLock().lock();
                    document.removeEventListener('scroll', lockScroll);
                }
            }
            document.addEventListener('scroll', lockScroll);
            self.find('.js-search-content').addClass('open');
            $('.main-nav-content').removeClass('open');
            self.find('.js-search').trigger('focus');
            $('#launcher').addClass('no-display');
            $(document).on('click', handleClickOutside);
        });

        self.find('.js-close-search').on('click', function () {
            closeSearch();
        });

        self.on('keyup', function(e) {
            if (self.find('.js-search-content').hasClass('open')) {
                if (e.key === 'Escape') {
                    closeSearch();
                } else if (e.key === 'Enter') {
                    const res = encodeURIComponent(self.find('.js-search-input').val().replace(/^[^a-zA-Z]+|[^a-zA-Z]+$/g, ''));

                    if (typeof trackSearchGAEvent === 'function') {
                        trackSearchGAEvent(res);
                    }

                    setTimeout(function () {
                        window.location.href = `/search?q=${res}`;
                    }, 500);
                }
            }
        });

        generalTabs('.js-main-menu-mobile', '.js-main-menu-item-mobile', '.js-menu-content', 'active');
        generalTabs('.js-main-menu-desktop', '.js-main-menu-item-desktop', '.js-menu-content', 'active');

        initSuitcaseCounter();

        function closeSearch() {
            self.find('.js-search-content').removeClass('open');
            self.find('.js-search-input').val('');
            $('.main-nav-container').find('.search-input').val('');
            $('#launcher').removeClass('no-display');
            clearSearch();
            scrollLock().unlock();
        }

        function handleClickOutside(event) {
            if (!$(event.target).closest('.main-nav').length) {
                closeSearch();

                $(document).off('click', handleClickOutside);
            }
        }

        function initSuitcaseCounter() {
            cartBookings.bookings.length > 0 && self.find('.suitcase-icon-counter').removeClass('no-display').text(cartBookings.bookings.length);
        }

        function search(event) {
            const searchTerm = self.find('.js-search-input').val();
            if (searchTerm.length >= 1) {
                if (typeof trackSearchGAEvent === 'function') {
                    trackSearchGAEvent(searchTerm);
                }

                $.ajax({
                    url: '/api/search?q=' + searchTerm,
                    type: 'GET',
                    contentType: 'application/json',
                    success: function (response) {
                        self.find('.js-products').empty();
                        if (response.data.experiences.length) {
                            const searchResults = response.data.popular_searches.map(searchResult => `<li><a href='${searchResult.link}' tabindex="0">${searchResult.title}</a></li>`);
                            self.find('.js-popular-search-terms').addClass('no-display');
                            self.find('.js-search-results').removeClass('no-display');
                            self.find('.js-search-results-list').empty().append(searchResults);

                            if (experienceCard) {
                                response.data.experiences.map(experience => self.find('.js-products').append(getNavBarProductCard(experience)));
                            }
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.error('Error occurred:', textStatus, errorThrown);
                    }
                });
            } else {
                clearSearch();
            }
        }

        function getNavBarProductCard(experience) {
            const newExpCard = $(experienceCard);
            newExpCard.find('.js-product-title').text(experience.title);

            if (isMobile) {
                newExpCard.find('.js-image').attr('src', experience.navBarImage?.mobile?.url).attr('alt', experience.navBarImage?.mobile?.alt || '');
            } else {
                newExpCard.find('.js-image').attr('src', experience.navBarImage?.desktop?.url).attr('alt', experience.navBarImage?.desktop?.alt || '');
            }

            newExpCard.find('.js-product-link').attr('href', experience.link);
            
            return newExpCard;
        }

        function clearSearch() {
            self.find('.js-popular-search-terms').removeClass('no-display');
            self.find('.js-search-results').addClass('no-display');
        }

        function openBurgerMenu() {
            $('.promo-banner-close').trigger('click');
            self.find('.menu-burger').addClass('show');
            if (isMobile) {
                scrollLock().lock();
                window.scrollBy(0, window.innerHeight);
            }
        }
    
        function closeBurgerMenu() {
            self.find('.menu-burger').removeClass('show');
            self.find('.main-nav-content').removeClass('open');
            scrollLock().unlock();
        }
    };
};

new SelectorInitiator().setObject(new MainNav());