'use strict';

function insertSpinner(targetElem, config) {
    const opts = {
        lines: config.hasOwnProperty('lines') ? config.lines : 10,
        length: config.hasOwnProperty('length') ? config.length : 11,
        width: config.hasOwnProperty('width') ? config.width : 9,
        radius: config.hasOwnProperty('radius') ? config.radius : 25,
        scale: config.hasOwnProperty('scale') ? config.scale : 1.05,
        corners: config.hasOwnProperty('corners') ? config.corners : 1,
        color: config.hasOwnProperty('color') ? config.color : '#FAB64EFF',
        speed: config.hasOwnProperty('speed') ? config.speed : 0.9,
        rotate: config.hasOwnProperty('rotate') ? config.rotate : 1,
        animation: config.hasOwnProperty('animation') ? config.animation : 'spinner-line-fade-quick',
        direction: config.hasOwnProperty('direction') ? config.direction : 1,
        zIndex: config.hasOwnProperty('zIndex') ? config.zIndex : 2e9,
        className: config.hasOwnProperty('className') ? config.className : 'spinner',
        top: config.hasOwnProperty('top') ? config.top : '50%',
        left: config.hasOwnProperty('left') ? config.left : '50%',
        shadow: config.hasOwnProperty('shadow') ? config.shadow : '0 0 1px transparent',
        position: config.hasOwnProperty('position') ? config.position : 'absolute'
    };

    return new Spinner(opts).spin(document.querySelector(targetElem));
}
function showLoader(selector) {
    selector = selector || 'body';
    const $elem= $(selector);
    $elem.append('<div class="loader"></div>');
    $('.loader').css({
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'auto',
        overflowX: 'hidden',
        background: 'rgba(128, 128, 128, 0.4)',
        zIndex: 100000000000001
    });
    $('body').css({
        overflow: 'hidden',
    });
    $elem.children().not('.loader').css('pointer-events', 'none');
    if (typeof Spinner === 'undefined') {
        $.getScript('https://cdnjs.cloudflare.com/ajax/libs/spin.js/2.3.2/spin.min.js', function() {
            insertSpinner('.loader', {position: 'absolute'});
        });
    } else {
        insertSpinner('.loader', {position: 'absolute'});
    }
}

function hideLoader(selector) {
    selector = selector || 'body';
    const $elem= $(selector);
    $elem.find('.loader').hide(300);
    $elem.find('.loader').remove();
    $elem.children().not('.loader').css('pointer-events', 'auto');
    $('body').css({
        overflow: 'auto'
    });
}
