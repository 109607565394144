'use strict';

const PnService = function() {
    this.sbscrbVisitor = function () {
        Notification.requestPermission(function (status) {
            if (typeof status !== 'undefined' && status === wpnStatusGrntd) {
                navigator.serviceWorker.getRegistration().then(function(reg) {
                    if (typeof reg === 'undefined') {
                        return;
                    }

                    reg.pushManager.getSubscription().then(function(sub) {
                        if (sub === null) {
                            // -- Not subscribed to push service
                            subscribeUser();
                        } else {
                            // -- We have a subscription, update the database
                            sendSubscription(sub, 'PUT');
                        }
                    }).catch(function(e) {
                        console.error('Unable to sunscrb to psh', e);
                    });
                });
            } else {
                // -- send user data to Klaviyo and ask it to allow Notifications
            }
        });
    };

    const subscribeUser = function () {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(function(reg) {
                reg.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(wpnPublicKey)
                }).then(function(sub) {
                    return sendSubscription(sub, 'POST');
                }).catch(function(e) {
                    if (Notification.permission === wpnStatusDnd) {
                        console.warn('Permission for notif was denied');
                    } else {
                        console.error('Unable to sbscrb to psh', e);
                    }
                });
            });
        }
    };

    const sendSubscription = function (subscription, method) {
        const key = subscription.getKey('p256dh');
        const token = subscription.getKey('auth');
        const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];

        return fetch('/api/push-notifications/subscribe', {
            method,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                HTTP_X_REQUEST_WIDTH: 'XMLHttpRequest',
            },
            body: JSON.stringify({
                endpoint: subscription.endpoint,
                publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
                authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
                contentEncoding,
            }),
        }).then(() => subscription);
    };

    const urlBase64ToUint8Array = function (base64String) {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }

        return outputArray;
    };
};