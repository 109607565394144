'use strict';

const SuitcasePreview = function() {
    this.selector = '.suitcase-preview';
    let suitcasePreview, suitcaseBTN, suitcaseCloseBTN;
    let cart;
    let suitcasePageService;
    let self = this;

    this.init = function() {
        if (typeof cartBookings === 'undefined') {
            return false;
        }
        cart = cartBookings;
        suitcasePreview = document.querySelector(this.selector);
        suitcaseBTN = document.querySelector('.suitcase-btn');
        suitcaseCloseBTN = document.querySelector('.suitcase-preview-close');

        suitcasePageService = new SelectorInitiator().getObjectBySelector('.suitcase-page');

        if (typeof suitcasePageService !== 'undefined') {
            suitcasePageService.deleteBookingSubscribe(newCart => rebuildCart(newCart));
            suitcasePageService.updateBookingSubscribe(newCart => rebuildCart(newCart));
        }

        checkExperienceCardsAndSetEvent();
    };

    let rebuildCart = function(newCart) {
        cart = newCart;
        checkExperienceCardsAndSetEvent();
    };

    const openPreview = function() {
        suitcasePreview.classList.toggle('active');
    };

    const closePreview = function() {
        suitcasePreview.classList.remove('active');
    };

    const checkWhereYouClick = function(event) {
        const containerSuitcasePreview =
            event.target === suitcasePreview || suitcasePreview.contains(event.target);
        const iconSuitcase = event.target === suitcaseBTN || suitcaseBTN.contains(event.target);
        const isActive = suitcasePreview.classList.contains('active');
        !containerSuitcasePreview && !iconSuitcase && isActive ? closePreview() : null;
    };

    const checkExperienceCardsAndSetEvent = function() {
        Object.values(document.querySelectorAll('.experience-card')).forEach(item => item.remove());

        if (cart.bookings.length) {
            suitcaseBTN.classList.add('active');
            document.querySelectorAll('.suitcase').forEach(element => {
                element.classList.add('show');
            });

            suitcaseBTN.addEventListener('click', openPreview);
            suitcaseCloseBTN.addEventListener('click', closePreview);
            document.addEventListener('click', checkWhereYouClick);

            self.drawCounterNumber();
            drawExperienceCards();
        } else {
            suitcaseBTN.classList.remove('active');
            document.querySelectorAll('.suitcase').forEach(element => {
                element.classList.remove('show');
            });
            suitcaseBTN.removeEventListener('click', openPreview);
            suitcaseCloseBTN.removeEventListener('click', closePreview);
            document.removeEventListener('click', checkWhereYouClick);
            document.querySelector('.suitcase-icon-counter').textContent = '';
            document.querySelector('.suitcase-preview-title').textContent = '';
        }
    };

    this.drawCounterNumber = function() {
        document.querySelector('.suitcase-icon-counter').textContent = cart.bookings.length;
        document.querySelector('.suitcase-preview-title').textContent =
            cart.bookings.length > 1
                ? `${cart.bookings.length} Experiences in your suitcase`
                : `${cart.bookings.length} Experience in your suitcase`;
    };

    const drawExperienceCards = function() {
        let totalPrice = 0;
        cart.bookings.map(booking => {
            document
                .querySelector('.suitcase-preview-content')
                .append(createExperienceCard(booking));
            totalPrice += Number(booking.price.replace(',', ''));
            document.querySelector('.suitcase-preview-total .total').textContent =
                booking.currencySymbol +
                parseFloat(totalPrice)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        });
    };

    /**
     *
     * @param {obj} - A obj param
     *
     */
    const createExperienceCard = function(booking) {
        const adultsText = booking.number_adults === 1 ? 'adult' : 'adults';
        const childrenText = booking.number_children === 1 ? 'child' : 'children';

        const content = `<h3 class="translatable">${booking.title}</h3>
			<div class="experience-card-content">
				<div class="experience-card-img">
					<img src="${booking.image}" alt="experience image" title="${booking.title}"> 
				</div>
				<div class="experience-card-info">
					<p><i class="pin-icon"></i>
						<span class="translatable">${booking.location}</span>
					</p>
					
					<p>
						<i class="calendar-icon"></i>
						<span class="translatable">${booking.date}</span>
					</p>
					
					<p>
						<i class="users-group-icon"></i>
						<span class="translatable">${booking.number_adults} ${adultsText}${booking.number_children !== 0 ? `, ${booking.number_children} ${childrenText}` : ''}</span>
					</p>
				</div>
				<p class="experience-card-price">${booking.currencySymbol}${booking.price}</p>
			</div>`;

        const parentContainer = document.createElement('section');
        parentContainer.className = 'experience-card';
        parentContainer.innerHTML = content;

        return parentContainer;
    };
};
new SelectorInitiator().setObject(new SuitcasePreview());