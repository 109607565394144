function scrollLock() {
    function lock() {
        document.body.style.overflow = 'hidden';
    }

    function unlock() {
        document.body.style.overflow = '';
    }

    return { lock, unlock };
}